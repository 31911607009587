import React from "react"

import { store } from "@/types/interface"
import CourseTypeCard from "./course-type-card"
import styles from "./program.module.css"

import kidsImg from "../../../../static/kids.jpg"
import excursionImg from "../../../../static/excursion.jpg"
import newImg from "../../../../static/new.png"
import businessImg from "../../../../static/kids-business.jpg"

type Props = {
  courseTypes: store.CourseType["courseTypes"]
}

const Program: React.FC<Props> = ({ courseTypes }) => {
  return (
    <section id="program" className={styles.programSection}>
      <h2 className={styles.title}>各種講座</h2>
      <div className={styles.cardGrid}>
        <CourseTypeCard courseType={newCourseType} linkTo="/new" img={newImg} />
        {courseTypes?.map(courseType => (
          <CourseTypeCard key={courseType._id} courseType={courseType} />
        ))}
        <CourseTypeCard courseType={excursionCourseType} linkTo="/excursion" img={excursionImg} />
        <CourseTypeCard courseType={kidsCouresType} linkTo="/kids" img={kidsImg} />
        <CourseTypeCard courseType={businessCouresType} linkTo="/global-business" img={businessImg} />
      </div>
    </section>
  )
}

// special course types which added into program section manually
const newCourseType: NonNullable<store.CourseType["courseType"]> = {
  _id: "new-course",
  name: "新講座＆最新広告",
  forKids: false
}

const excursionCourseType: NonNullable<store.CourseType["courseType"]> = {
  _id: "excursion",
  name: "大人の遠足",
  forKids: false
}

const kidsCouresType: NonNullable<store.CourseType["courseType"]> = {
  _id: "kids",
  name: "キッズ",
  forKids: true
}

const businessCouresType: NonNullable<store.CourseType["courseType"]> = {
  _id: "global-business",
  name: "キッズ・ビジネス",
  forKids: true
}

export default Program
