import React from "react"

import { store } from "@/types/interface"
import styles from "./company.module.css"

type Props = {
  siteInformation: store.SiteInformation["siteInformation"]
}

const Company: React.FC<Props> = ({ siteInformation }) => {
  return (
    <section id="company" className={styles.companySection}>
      <h2 className={styles.title}>Company</h2>
      <table>
        <tbody>
          <tr>
            <td>運営</td>
            <td>{siteInformation?.company}</td>
          </tr>
          <tr>
            <td>本社所在地</td>
            <td>{siteInformation?.address}</td>
          </tr>
          <tr>
            <td>TEL</td>
            <td>{siteInformation?.tel}</td>
          </tr>
          <tr>
            <td>FAX</td>
            <td>{siteInformation?.fax}</td>
          </tr>
          <tr>
            <td>事業内容</td>
            <td>{siteInformation?.buisinessContent}</td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default Company
