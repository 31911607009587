import React from "react"
import { Link } from "gatsby"

import { store } from "@/types/interface"
import styles from "./course-type-card.module.css"

type Props = {
  courseType: store.CourseType["courseType"]
  linkTo?: string
  img?: string
}

const CourseTypeCard: React.FC<Props> = ({ courseType, linkTo, img }) => {
  const cardImg = {
    backgroundImage: img ? `url(${img})` : `url(${courseType?.imagePath})`
  }

  return (
    <Link
      to={linkTo ? linkTo : "/course-type/" + courseType?._id}
      style={{ display: "block" }}
    >
      <div className={styles.card} style={cardImg}>
        <div className={styles.cardCover}>
          <div className={styles.textbox}>{courseType?.name}</div>
        </div>
      </div>
    </Link>
  )
}

export default CourseTypeCard
