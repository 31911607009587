import React from "react"
import { connect, ConnectedProps } from "react-redux"

import { Layout } from "../components"
import { SEO } from "../components/common"
import {
  TopPhotos,
  Program,
  Recruit,
  Access,
  News,
  Company,
} from "../components/top"
import { store } from "@/types/interface"
import { courseTypeLoad, newsLoad, getSiteInformation } from "../actions"

/**
 * Top page (home page)
 */
const connector = connect(
  // mapStateToProps
  ({
    courseType,
    news,
    siteInformation,
  }: {
    courseType: store.CourseType
    news: store.News
    siteInformation: store.SiteInformation
  }) => ({
    courseTypes: courseType.courseTypes,
    news: news.news,
    siteInformation: siteInformation.siteInformation,
  }),
  // mapDispatchToProps
  { courseTypeLoad, newsLoad, getSiteInformation }
)

type Props = ConnectedProps<typeof connector>

const Index: React.FC<Props> = ({
  courseTypes,
  courseTypeLoad,
  news,
  newsLoad,
  siteInformation,
  getSiteInformation,
}) => {
  React.useEffect(() => {
    courseTypeLoad()
    newsLoad()
    getSiteInformation()
  }, [])

  return (
    <Layout>
      <SEO />
      {siteInformation && <TopPhotos imagePaths={siteInformation.imagePaths} />}
      <div className="container">
        <News news={news} />
        <Program courseTypes={courseTypes} />
        <Access />
        <Recruit />
        <Company siteInformation={siteInformation} />
      </div>
    </Layout>
  )
}

export default connector(Index)
