import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { RecruitQuery } from "@/types/graphql-types"
import styles from "./recruit.module.css"

const recruitQuery = graphql`
  query Recruit {
    site {
      siteMetadata {
        place
        businessTime
      }
    }
  }
`

const Recruit: React.FC = () => {
  const data: RecruitQuery = useStaticQuery(recruitQuery)
  const siteMetadata = data.site?.siteMetadata

  return (
    <section id="recruit" className={styles.recruitSection}>
      <h2 className={styles.title}>Recruit</h2>
      <div className={styles.textBox}>
        <p>場所 : {siteMetadata?.place}</p>
        <p>時間 : {siteMetadata?.businessTime}</p>
        <p>
          ＊時間・曜日相談の上、シフト組します。
          <br />
          簡単なPC入力のできる方。
        </p>
        <p>
          ＊週2回程度、18時〜勤務可能な方。
          <br />
          土日勤務可能な方、大歓迎！
        </p>
      </div>
    </section>
  )
}

export default Recruit
