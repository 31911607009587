import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { AccessQuery } from "@/types/graphql-types"
import styles from "./access.module.css"

const accessQuery = graphql`
  query Access {
    site {
      siteMetadata {
        postalCode
        address
        building
      }
    }
  }
`

const Access: React.FC = () => {
  const data: AccessQuery = useStaticQuery(accessQuery)
  const siteMetadata = data.site?.siteMetadata

  return (
    <section id="access" className={styles.accessSection}>
      <div className={styles.accessSide}>
        <h2 className={styles.title}>Access</h2>
        <div className={styles.textBox}>
          <p>〒{siteMetadata?.postalCode}</p>
          <p>{siteMetadata?.address}</p>
          <p>{siteMetadata?.building}</p>
        </div>
      </div>
      <div className={styles.mapBox}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.586480778201!2d135.65182171565354!3d34.7911840858736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60011c2767774651%3A0x6581e983c8d18c49!2z44Kr44Or44OB44Oj44O844OP44Km44K56aaZ6YeM44Kx5LiY!5e0!3m2!1sja!2sjp!4v1587450478164!5m2!1sja!2sjp"
          className={styles.googleMap}
          allow="fullscreen"
          aria-hidden="false"
        ></iframe>
      </div>
    </section>
  )
}

export default Access
