import React from "react"
import CSS from "csstype"
import { Link } from "gatsby"
import reactStringReplace from "react-string-replace"

import { store } from "@/types/interface"
import styles from "./news.module.css"

type Props = {
  news: store.News["news"]
}

const News: React.FC<Props> = ({ news }) => {
  const linkRegex = /https?:\/\/culture-house.com(\/\S+)/g
  return (
    <section className={styles.newsSection}>
      <h2 className={styles.title}>News</h2>
      {news?.slice(0, 4).map(news => (
        <div key={news._id} className={styles.newsCard}>
          <div className={styles.date}>{getDate(news.date)}</div>
          <h3>{news.title}</h3>
          <NewsContent>
            {reactStringReplace(news.content, linkRegex, (match, i) => (
              <Link key={i} to={match}>
                {`https://culture-house.com${match}`}
              </Link>
            ))}
          </NewsContent>
        </div>
      ))}
    </section>
  )
}

const NewsContent: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [openStyle, setOpenStyle] = React.useState<CSS.Properties>({
    height: "auto",
  })
  const targetRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (targetRef.current) {
      setOpenStyle({
        height: `${targetRef.current.offsetHeight}px`,
      })
      setIsOpen(false)
    }
  }, [])

  return (
    <div className={styles.content}>
      <button
        className={
          isOpen ? `${styles.toggleBtn} ${styles.open}` : styles.toggleBtn
        }
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? "閉じる" : "内容を表示"}
      </button>
      <p
        className={styles.item}
        style={isOpen ? openStyle : undefined}
        ref={targetRef}
      >
        {children}
      </p>
    </div>
  )
}

const getDate = (strDate: string): string => {
  const date = new Date(strDate)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return year + "/" + month + "/" + day
}

export default News
