import React from "react"

import { Slider } from "../../common"
import { useWindowSize } from "../../../utils/useWindowSize"
import styles from "./top-photos.module.css"

type Props = {
  imagePaths: string[]
}

const TopPhotos: React.FC<Props> = ({ imagePaths }) => {
  const targetRef = React.useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })
  const size = useWindowSize()

  React.useEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
  }, [size])

  return (
    <div className={styles.sliderContainer} ref={targetRef}>
      {dimensions.width != 0 && (
        <Slider
          slideWidth={dimensions.width}
          slideHeight={dimensions.height}
          photos={imagePaths}
          isStatic={false}
          speed={1.2}
          objectFit="cover"
        />
      )}
      {/* <div className={styles.textBox}>
        <p>新しい出会いにドキドキ・ワクワク</p>
      </div> */}
    </div>
  )
}

export default TopPhotos
